import React from 'react';
import Seo from '../components/Seo';
import Main from '../components/Main';
import Takeover from '../components/Takeover';
import ProjectCarousel from '../components/ProjectCarousel';
import IntroVideo from '../components/IntroVideo';

export default ({
	pageContext,
	location,
}) => {
	const {seo = {}} = pageContext;
	
	return (
		<React.Fragment>
			<Seo
				metaTitle={seo.metaTitle}
				metaDescription={seo.metaDescription}
				metaKeywords={seo.metaKeywords}
				openGraphTitle={seo.openGraphTitle}
				openGraphDescription={seo.openGraphDescription}
				openGraphImage={seo.openGraphImage}
				twitterTitle={seo.twitterTitle}
				twitterDescription={seo.twitterDescription}
				twitterImage={seo.twitterImage}
				pathname={location.pathname}
			/>
			<ProjectCarousel
				projects={pageContext.config.projects}
			/>
			<Main
				title={pageContext.config.siteTitle}
			/>
			<Takeover
				content={pageContext.takeover.content}
			/>
		</React.Fragment>
	);
};